import React from "react"
import { graphql, PageProps } from "gatsby"
import { LegalNoticeQuery } from "../../graphql-types"
import SimplePage from "../components/SimplePage"

const LegalNoticePage: React.FC<PageProps<LegalNoticeQuery>> = ({ data }) => {
  const title = data.file?.childMarkdownRemark?.frontmatter?.title
  const html = data.file?.childMarkdownRemark?.html

  if (!title || !html) {
    return <></>
  }

  return <SimplePage title={title} html={html} />
}

export default LegalNoticePage

export const pageQuery = graphql`
  query LegalNotice {
    file(sourceInstanceName: { eq: "pages" }, name: { eq: "legal-notice" }) {
      childMarkdownRemark {
        html
        frontmatter {
          title
        }
      }
    }
  }
`
